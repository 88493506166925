
@media print {
  body * {
    visibility: hidden;
    
  }
   #section-to-print *{
     
    visibility: visible;
    margin: 0 0 0 0;

    width: auto;
   
font-weight: normal;
    font-size: 14;
  }
  #section-to-print {
    position: auto;
    left: 0;
    top: 0;
    margin-top: 0;

  }
  #section-to-print .img-result{
    width: 150px;
    height: 120px;

  }
  #section-to-print .result-logo{
    width: 150px;
    height: 120px;
  }
  #section-to-print .table{
    width: 100%;
    overflow: hidden;
    position: relative;
   font-weight: normal;
    font-size: 14px;
  }

  #section-to-print .table-responsive{
    visibility: hidden;

  }
  #section-to-print .action{
    visibility: hidden;

  }
  #section-to-print .table{
    border-color: black;
     color: black;
  }
   #sidebar{
     display: none;
   }
    #navbar{
     display: none;
   }
}